/**
 *  Array dari semua route yang dapat di akses public
 *  route ini tidak membutuhkan authentication
 * @type {string[]}
 */
export const publicRoutes = [
  "/",
  "/public-class",
  "/onboarding",
  "/auth/google/success",
  "/auth/google/failed",
]

/**
 *  route ini  untuk authentication
 * @type {string[]}
 */
export const authRoutes = ["/login"]

/**
 *  Prefix untuk API authentication routes
 *  route ini untuk auth
 * @type {string}
 */
export const apiAuthPrefix = "/api/auth"

/**
 *  default route untuk redirect setelah login
 * @type {object}
 */
export const redirectAfterLogin = {
  mahasiswa: "/mahasiswa",
  dosen: "/dosen",
}

export const DOSEN_ROUTE = "/dosen"
export const MAHASISWA_ROUTE = "/mahasiswa"
export const MATAKULIAH_ROUTE = "/dosen/mata-kuliah"
export const TUGAS_KELAS_ROUTE = "/dosen/tugas-kelas"
export const DOSEN_MATERI_KELAS_ROUTE = `${DOSEN_ROUTE}/materi-kelas`
export const KUIS_KELAS_ROUTE = DOSEN_ROUTE + "/kuis"
export const AUTH_ROUTE = "/auth"
