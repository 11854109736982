import { AiFillCustomerService } from "react-icons/ai"
import { z } from "zod"

const LoginFormSchema = z.object({
  username: z.string().min(2, {
    message: "Nim must be at least 2 characters.",
  }),
  password: z.string().min(1, {
    message: "Password must be at least 6 characters.",
  }),
})

const FormMateriVideoSchema = z.object({
  idPertemuan: z
    .string({
      required_error: "Tentukan jumlah pertemuan!",
    })
    .min(1, { message: "Pertemuan tidak boleh kosong." }),
  judul: z
    .string({
      required_error: "Judul materi tidak boleh kosong.",
    })
    .min(3, {
      message: "Minimal karakter judul materi adalah 3.",
    })
    .max(50, {
      message: "Judul materi tidak boleh melebihi 50 karakter.",
    }),
  jenis: z
    .string({
      required_error: "Tentukan jenis materi!",
    })
    .min(1, { message: "Jenis materi tidak boleh kosong." }),

  deskripsi: z.string(),
  videoUrl: z.string().min(3, {
    message: "Minimal karakter video adalah 3.",
  }),
})

const FormMateriDokumenSchema = z.object({
  idPertemuan: z
    .string({
      required_error: "Tentukan jumlah pertemuan!.",
    })
    .min(1, {
      message: "Required.",
    }),
  jenis: z
    .string({
      required_error: "Tentukan jenis materi!.",
    })
    .min(1, {
      message: "Required.",
    }),
  judul: z
    .string({
      required_error: "Judul materi tidak boleh kosong.",
    })
    .min(3, {
      message: "Minimal karakter judul materi adalah 3.",
    })
    .max(50, {
      message: "Judul materi tidak boleh melebihi 50 karakter.",
    }),

  documentFile: z.any(),
})
const FormTugasMahasiswaSchema = z.object({
  documentFile: z.instanceof(File).refine((file) => file instanceof File, {
    message: "File tugas tidak boleh kosong.",
  }),
})

const FormMateriArtikelSchema = z.object({
  idPertemuan: z
    .string({
      required_error: "Tentukan jumlah pertemuan!.",
    })
    .min(1, {
      message: "Required.",
    }),
  jenis: z
    .string({
      required_error: "Tentukan jenis materi!.",
    })
    .min(1, {
      message: "Required.",
    }),
  judulmateri: z
    .string({
      required_error: "Judul materi tidak boleh kosong.",
    })
    .min(3, {
      message: "Minimal karakter judul materi adalah 3.",
    })
    .max(50, {
      message: "Judul materi tidak boleh melebihi 50 karakter.",
    }),
  deskripsi: z.string().min(3, {
    message: "Minimal karakter materi adalah 3.",
  }),
})

const FormCreateTugasSchema = z.object({
  idPertemuan: z
    .string({
      required_error: "Tentukan jumlah pertemuan!.",
    })
    .min(1, {
      message: "Required.",
    }),
  idMateri: z
    .string({
      required_error: "Tentukan jumlah pertemuan!.",
    })
    .min(1, {
      message: "Required.",
    }),
  jenis: z
    .string({
      required_error: "Tentukan jenis tugas!.",
    })
    .min(1, {
      message: "Required.",
    }),
  judul: z
    .string({
      required_error: "Judul tugas tidak boleh kosong.",
    })
    .min(3, {
      message: "Minimal karakter judul tugas adalah 3.",
    })
    .max(50, {
      message: "Judul tugas tidak boleh melebihi 50 karakter.",
    }),
  description: z.string().min(3, {
    message: "Minimal karakter tugas adalah 3.",
  }),
})

const FormCreateForumDiksusiSchema = z.object({
  idPertemuan: z.string().min(1, { message: "Pertemuan tidak boleh kosong." }),
  judul: z.string().min(3, { message: "Judul tidak boleh kosong." }),
  description: z.string().min(3, { message: "Deskripsi tidak boleh kosong." }),
})
const FormCreateForumMeetSchema = z.object({
  idPertemuan: z.string().min(1, { message: "Pertemuan tidak boleh kosong." }),
  judul: z.string().min(3, { message: "Judul tidak boleh kosong." }),
  description: z.string().min(3, { message: "Deskripsi tidak boleh kosong." }),
})

const tugasMahasiswaSchema = z.object({
  fileTugas: z.array(z.instanceof(File)).refine((files) => files.length > 0, {
    message: "File tugas tidak boleh kosong.",
  }),
})

const DiskusiMessage = z.object({
  id_forum_diskusi: z.string().min(1, {
    message: "Id forum diskusi tidak boleh kosong.",
  }),
  content: z.string().min(1, {
    message: "Komentar harus diisi minimal 1 karakter.",
  }),
})

const CatatanTugasSchema = z.object({
  catatan: z.string().min(1, {
    message: "Catatan harus diisi minimal 1 karakter.",
  }),
})
export default FormCreateForumMeetSchema
export {
  CatatanTugasSchema,
  FormCreateForumDiksusiSchema,
  DiskusiMessage,
  FormCreateForumMeetSchema,
  LoginFormSchema,
  FormMateriVideoSchema,
  FormMateriDokumenSchema,
  FormMateriArtikelSchema,
  FormCreateTugasSchema,
  tugasMahasiswaSchema,
  FormTugasMahasiswaSchema,
}
