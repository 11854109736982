import { fetchDataWithToken } from "@/api"
import { AUTH_ROUTE, TUGAS_KELAS_ROUTE } from "@/routes"

export async function getUrlAuthGoogle() {
  try {
    const response = await fetchDataWithToken({
      pathname: AUTH_ROUTE + "/login/google",
    })
    return {
      isError: false,
      errorMessage: "",
      data: response.data,
    }
  } catch (error) {
    return {
      isError: true,
      errorMessage: error.message,
    }
  }
}

export async function disconnectAuthGoogle() {
  try {
    const response = await fetchDataWithToken({
      pathname: AUTH_ROUTE + "/login/google/disconnect",
    })
    return {
      isError: false,
      errorMessage: "",
      data: response.data,
    }
  } catch (error) {
    return {
      isError: true,
      errorMessage: error.message,
    }
  }
}

export async function getUrlConnectAuthGoogle() {
  try {
    const response = await fetchDataWithToken({
      pathname: AUTH_ROUTE + "/login/google/connect",
    })
    return {
      isError: false,
      errorMessage: "",
      data: response.data,
    }
  } catch (error) {
    return {
      isError: true,
      errorMessage: error.message,
    }
  }
}

export async function cekConnectAuthGoogle() {
  try {
    const response = await fetchDataWithToken({
      pathname: AUTH_ROUTE + "/login/google/check",
    })
    console.log(response, "resssssnyaa")
    if (!response?.status) {
      return {
        isError: true,
        errorMessage: "Gagal mendapatkan data",
      }
    } else {
      return {
        isError: false,
        errorMessage: "",
        data: response.data,
      }
    }
  } catch (error) {
    return {
      isError: true,
      errorMessage: error.message,
    }
  }
}
